.large-header {
  font-size: 3rem;
  padding: 0px;
  margin: 0px;
}

.medium-header {
  font-size: 3rem;
  padding: 0px;
  margin: 0px;
}

.small-header {
  font-size: 2rem;
  padding: 0px;
  margin: 0px;
}

.container-col {
  display: flex;
  flex-direction: column;
}

.container-row {
  display: flex;
  flex-direction: row;
}

.justify-center {
  justify-content: center;
}

.center {
  align-items: center;
  justify-content: center;
}

.screen-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content {
  font-size: 1.5rem;
  color: #416165;
}

.fade-in {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 481px) {
  .large-header {
    font-size: 4rem;
  }
}

@media screen and (min-width: 769px) {
  .large-header {
    font-size: 5rem;
  }
}

@media screen and (min-width: 1025px) {
  .large-header {
    font-size: 6rem;
  }
}
