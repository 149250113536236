@media screen and (min-width: 0px) {
  .open-nav {
    display: none;
  }

  .nav-bar-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 25px;
    padding: 30px;
  }

  .nav-bar-link {
    padding: 15px;
    border-radius: 40px;
    color: inherit;
    text-decoration: none;
  }

  .close-nav {
    display: none;
  }

  .nav-bar-link:hover {
    background-color: #e2e3f1;
  }

  .active-button {
    border: 2px solid;
  }

  @keyframes animated-border {
    0% {
      width: 0;
      height: 0;
      border-top-color: black;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }

    50% {
      width: 100%;
      height: 0;
      border-top-color: black;
      border-right-color: black;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }

    100% {
      width: 100%;
      height: 100%;
      border-top-color: black;
      border-right-color: black;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
  }
}
