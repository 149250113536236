.icon {
  border-radius: 100px;
  width: 40px;
  height: 40px;
}

.timeline-header {
  font-size: 3em;
}

.timeline-container {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-bottom: 80px;
  width: 100%;
}

@media screen and (min-width: 1170px) {
  .icon {
    width: 60px;
    height: 60px;
  }

  .box-fill {
    align-self: center;
    width: 30px;
    min-height: 30px;
    background-color: #416165;
  }
}
